export interface SigninInput {
  email: string;
  password: string;
}

export interface IUser {
  _id: string;
  username: string;
  email: string;
  role: ERole;
  active: boolean;
  preference: any;
  avatarPath?: string;
  backgroundPath?: string;
  expiredTime?: string;
  isDeleted?: boolean;
  personalization?: any;
}

export interface IEditInfoUser {
  username: string;
  email: string;
  password: string;
  newPassword: string;
  preference: Array<any>;
  avatar: File;
  isChangePassword: boolean;
}

export interface IChangeStatus {
  id: string;
  status: boolean;
}

export interface ICreateMultipleUser {
  users: {
    username: string;
    email: string;
    active: boolean;
  }[];
}

export enum ERole {
  ADMIN = "admin",
  SUPER_ADMIN = "super_admin",
  USER = "user",
}
