import { IApplicationState } from "@/store-redux";
import CloseIcon from "@mui/icons-material/Close";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Dialog, IconButton, Tab } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import TabHighlight from "./TabHighlight";
import TabSpeakingSpeed from "./TabSpeakingSpeed";

interface IPracticeModalProps {
  open: boolean;
  handleClose: () => void;
  boldIndexes: number[];
  setBoldIndexes: React.Dispatch<React.SetStateAction<number[]>>;
}

function PracticeModal({ open, handleClose, boldIndexes, setBoldIndexes }: IPracticeModalProps) {
  const detailQuestion = useSelector((state: IApplicationState) => state.detailItem);

  const [tabValue, setTabValue] = useState("1");

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          minWidth: "100vw",
          minHeight: "100vh",
          padding: "32px",
        },
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box
          dangerouslySetInnerHTML={{
            __html: detailQuestion?.data?.name ?? "",
          }}
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
          }}
        />
        <IconButton
          onClick={() => handleClose()}
          sx={{
            height: "40px",
            padding: "8px",
            borderRadius: "50%",
            fontSize: "16px",
            fontWeight: 700,
            textTransform: "none",
          }}
        >
          <CloseIcon color="action" />
        </IconButton>
      </Box>
      <Box>
        <TabContext value={tabValue}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{
                sx: {
                  height: "2px",
                },
              }}
              sx={(theme) => ({
                "& button": {
                  textTransform: "none",
                  color: theme.palette.secondary.main,
                },
                "& .Mui-selected": {
                  fontWeight: "bold",
                  color: theme.palette.secondary.main,
                },
              })}
            >
              {[
                { id: "1", label: "Highlight Text" },
                { id: "2", label: "Speaking Speed" },
              ].map((tab) => (
                <Tab label={tab.label} value={tab.id} key={tab.id} />
              ))}
            </TabList>
          </Box>
          <Box>
            <TabPanel value="1">
              <TabHighlight boldIndexes={boldIndexes} setBoldIndexes={setBoldIndexes} />
            </TabPanel>
            <TabPanel value="2">
              <TabSpeakingSpeed />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Dialog>
  );
}

export default PracticeModal;
