import { Box, Button } from "@mui/material";
import { useState } from "react";
import CustomAnswerWordBold from "./CustomAnswerWordBold";
import CustomAnswerHighlight from "./CustomHighlight";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

enum ECurrentStepPractice {
  ANSWER_WORD_BOLD = "ANSWER_WORD_BOLD",
  ANSWER_HIGHLIGHT = "ANSWER_HIGHLIGHT",
}

interface IProps {
  boldIndexes: number[];
  setBoldIndexes: React.Dispatch<React.SetStateAction<number[]>>;
}

function TabHighlight({ boldIndexes, setBoldIndexes }: IProps) {
  const [currentStep, setCurrentStep] = useState<ECurrentStepPractice>(ECurrentStepPractice.ANSWER_WORD_BOLD);
  const handleGoBack = () => {
    switch (currentStep) {
      case ECurrentStepPractice.ANSWER_WORD_BOLD:
        break;
      case ECurrentStepPractice.ANSWER_HIGHLIGHT:
        setCurrentStep(ECurrentStepPractice.ANSWER_WORD_BOLD);
        break;
      default:
        break;
    }
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {currentStep === ECurrentStepPractice.ANSWER_HIGHLIGHT && (
          <Button
            variant="outlined"
            onClick={handleGoBack}
            sx={{
              minWidth: "68px",
              height: "40px",
              padding: "6px 16px",
              borderRadius: "4px",
              fontSize: "16px",
              fontWeight: 700,
              textTransform: "none",
              "& svg": {
                marginRight: "4px",
              },
            }}
          >
            <ArrowBackIcon />
            Back
          </Button>
        )}
      </Box>
      {currentStep === ECurrentStepPractice.ANSWER_WORD_BOLD ? (
        <CustomAnswerWordBold
          nextStep={() => {
            setCurrentStep(ECurrentStepPractice.ANSWER_HIGHLIGHT);
          }}
          boldIndexes={boldIndexes}
          setBoldIndexes={setBoldIndexes}
        />
      ) : (
        <CustomAnswerHighlight boldIndexes={boldIndexes} />
      )}
    </Box>
  );
}

export default TabHighlight;
