import { ENDPOINTS, ETypeItem, getApiUrl, IApplicationState, IItem, IItems, Requests } from "@/store-redux";
import { Avatar, Box, Button, CircularProgress, IconButton, Input, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { extractContent, genCategoryAspect, getUrlServer, randomStringFromDatetime, refinePrompt } from "@/helpers";
import BotIcon from "./../../../../assets/bot.png";
import SuggestAnswerBtn from "../GuidedQuestionPage/components/SuggestAnswerBtn";
import SendIcon from "@mui/icons-material/Send";
import { QueriesOptions } from "react-query";
import { ROUTES } from "@/constants";
import { useNavigate } from "react-router-dom";

// Define types for state
interface AnswersByAspect {
  [key: string]: string;
}

interface ChatWithBotPart2Props {
  question: IItems;
}

const ChatWithBotPart2 = ({ question }: ChatWithBotPart2Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state: IApplicationState) => state.currentUser);
  const [aspectSelectedId, setAspectSelectedId] = useState<any[]>([]);
  const [isSubmitAspect, setIsSubmitAspect] = useState(false);
  const [answersByAspect, setAnswersByAspect] = useState<AnswersByAspect>({});
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [inputAns, setInputAns] = useState<string>("");
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<QueriesOptions[]>([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [name, setName] = useState<string | null>(null);
  const questionId = useMemo(() => question?._id, [question]);
  useEffect(() => {
    if (questionId) getGuidedQuestions();
  }, [questionId]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [answersByAspect]);

  const getGuidedQuestions = async () => {
    console.log("questionId", questionId);
    const dataRes = await Requests.get(
      getApiUrl(ENDPOINTS.GET_QUIDED_QUESTIONS.replace(":questionId", questionId ?? ""))
    );
    console.log(dataRes);
    if (Array.isArray(dataRes)) {
      const tempValue = dataRes?.filter((item) => {
        const existGuideQuestion = item?.aspects?.find((item: any) => item?.guidedQuestion);
        return existGuideQuestion ? true : false;
      });
      setItems(tempValue);
    }
  };

  const allGuidedQuestions = useMemo(() => {
    return items.reduce((acc, item) => {
      const category = { ...item };
      delete category?.aspects;
      return [
        ...acc,
        ...item?.aspects?.reduce((accA, itemA) => {
          const aspect = { ...itemA };
          delete aspect.guidedQuestion;
          return [
            ...accA,
            {
              ...itemA.guidedQuestion,
              aspect,
              category,
            },
          ];
        }, []),
      ];
    }, []);
  }, [items]);
  const guidedQuestions = useMemo(() => {
    return allGuidedQuestions.filter((item) => aspectSelectedId.includes(item?.aspect?._id));
  }, [aspectSelectedId]);

  const showMessageSystem = (content: string) => {
    return (
      <Box
        sx={{
          display: "flex",
          marginBottom: "20px",
          "& img": {
            width: "32px",
            height: "32px",
            marginRight: "5px",
          },
        }}
      >
        <img src={BotIcon} />
        <Box
          sx={{
            backgroundColor: "#F3F3FF",
            color: "#313131",
            fontSize: "14px",
            padding: "5px",
            borderRadius: "0 10px 10px 10px",
            maxWidth: "70%",
            "& span": {
              fontWeight: "bold",
            },
          }}
        >
          {content}
        </Box>
      </Box>
    );
  };

  const showMessageUser = (content: string) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#f1f1f1",
            padding: "10px",
            borderRadius: "10px 0 10px 10px",
            maxWidth: "70%",
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              "& *": {
                fontSize: "14px",
              },
            }}
            dangerouslySetInnerHTML={{ __html: content || "" }}
          ></Box>
        </Box>
        <Avatar
          sx={{
            marginLeft: "10px",
            fontSize: "32px",
            color: "#ff7269",
          }}
          src={currentUser.data?.avatarPath ? `${getUrlServer(currentUser.data?.avatarPath)}` : "/images/people.png"}
        />
      </Box>
    );
  };

  const onSubmitAns = (value?: string) => {
    if (!name) {
      setName(value ?? inputAns);
      setInputAns("");
      return;
    }
    setAnswersByAspect((prev) => ({
      ...prev,
      [guidedQuestions[currentIndex]?.aspect?._id]: value ?? inputAns,
    }));
    setInputAns("");
    setCurrentIndex((prev) => prev + 1);
  };

  const inputDisabled = inputAns.trim() === "" || guidedQuestions.length === Object.keys(answersByAspect).length;

  return (
    <Box>
      <Box
        sx={{
          overflowY: "auto",
          maxHeight: "calc(100vh - 240px)",
        }}
      >
        {!isSubmitAspect && (
          <>
            {showMessageSystem("Choose category and aspect")}
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid #C6C1C1",
              }}
            >
              {items.map((item, idx) => (
                <Box
                  key={idx}
                  sx={{
                    flex: 1,
                    padding: "5px",
                    textAlign: "center",
                    cursor: "pointer",
                    borderBottom: "2px solid transparent",
                    "&:hover": {
                      borderBottom: "2px solid #0A14FF",
                      fontWeight: "bold",
                    },
                    ...(selectedItem?._id === item._id
                      ? { borderBottom: "2px solid #0A14FF", fontWeight: "bold" }
                      : {}),
                  }}
                  onClick={() => setSelectedItem(item)}
                >
                  <Box
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    <Box dangerouslySetInnerHTML={{ __html: item.name ?? "" }}></Box>
                  </Box>
                </Box>
              ))}
            </Box>
            {selectedItem && (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {selectedItem.aspects?.map((aspect: any, idx: number) => {
                  if (!aspect?.guidedQuestion) return <></>;
                  return (
                    <Box
                      sx={{
                        padding: "5px 10px",
                        fontSize: "14px",
                        cursor: "pointer",
                        margin: "5px",
                        borderRadius: "5px",
                        border: "1px solid #C6C1C1",
                        "&:hover": {
                          border: "1px solid #0A14FF",
                          fontWeight: "bold",
                          color: "#0A14FF",
                        },
                        ...(aspectSelectedId.includes(aspect._id)
                          ? {
                              border: "1px solid #0A14FF",
                              fontWeight: "bold",
                              color: "#0A14FF",
                            }
                          : {}),
                      }}
                      key={idx}
                    >
                      <Box
                        dangerouslySetInnerHTML={{ __html: aspect.name ?? "" }}
                        onClick={() => {
                          setAspectSelectedId((prev) =>
                            prev.includes(aspect._id)
                              ? prev.filter((idItem) => idItem !== aspect._id)
                              : [...prev, aspect._id]
                          );
                        }}
                      ></Box>
                    </Box>
                  );
                })}
              </Box>
            )}
            {aspectSelectedId.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    marginRight: "10px",
                  }}
                >
                  {aspectSelectedId.length} selected
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    background: "#0A14FF",
                    color: "#fff",
                    textTransform: "unset",
                  }}
                  onClick={() => {
                    setIsSubmitAspect(true);
                  }}
                >
                  Submit
                </Button>
              </Box>
            )}
          </>
        )}
        {selectedItem && (
          <>
            {isSubmitAspect && aspectSelectedId?.length > 0 && (
              <Box
                sx={{
                  minHeight: "320px",
                }}
              >
                {showMessageSystem("What is the name of the (person / object / event) you want to describe?")}

                {name ? (
                  <>
                    {showMessageUser(name)}
                    {guidedQuestions.slice(0, currentIndex + 1).map((item) => (
                      <div key={item._id}>
                        <Box
                          sx={{
                            display: "flex",
                            marginBottom: "20px",
                            "& img": {
                              width: "32px",
                              height: "32px",
                              marginRight: "5px",
                            },
                          }}
                        >
                          {/* <SmartToyIcon
                            sx={{
                              marginRight: "10px",
                              fontSize: "36px",
                              color: "#ff7269",
                            }}
                          /> */}
                          <img src={BotIcon} />
                          <Box
                            sx={{
                              backgroundColor: "#F3F3FF",
                              color: "#313131",
                              padding: "5px",
                              borderRadius: "0 10px 10px 10px",
                              maxWidth: "70%",
                            }}
                          >
                            <Box
                              sx={{
                                fontSize: "14px",
                                "& *": {
                                  fontSize: "14px",
                                },
                              }}
                              dangerouslySetInnerHTML={{ __html: item.name ?? "" }}
                            ></Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "flex-end",
                              }}
                            >
                              <Box
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                  display: "flex",
                                  alignItems: "flex-end",
                                }}
                              >
                                <Box dangerouslySetInnerHTML={{ __html: item?.category?.name ?? "" }}></Box>
                                <Typography
                                  sx={{
                                    margin: "0 5px",
                                  }}
                                >
                                  -
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  fontSize: "12px",
                                }}
                              >
                                <Box dangerouslySetInnerHTML={{ __html: item?.aspect?.name ?? "" }}></Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        {answersByAspect[item?.aspect?._id] && showMessageUser(answersByAspect[item?.aspect?._id])}
                      </div>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </Box>
            )}
          </>
        )}
        <div ref={bottomRef} />
      </Box>
      {isSubmitAspect && aspectSelectedId?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            padding: "12px",
          }}
        >
          {guidedQuestions.length && guidedQuestions.length === Object.keys(answersByAspect).length ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  background: "#0A14FF",
                  color: "#fff",
                  textTransform: "unset",
                }}
                onClick={() => {
                  navigate(ROUTES.ANSWER_QUESTION.replace(":questionId", questionId ?? ""), {
                    state: { isPartTwo: true, question, guidedQuestions, answersByAspect, name },
                  });
                }}
              >
                {/* {isLoading && (
                  <CircularProgress
                    size={20}
                    sx={{
                      marginRight: "10px",
                    }}
                  />
                )} */}
                Customized
              </Button>
            </Box>
          ) : (
            <>
              <Input
                sx={{
                  flex: 1,
                  border: "1px solid #e0e0e0",
                  borderRadius: "10px",
                  padding: "0 10px",
                  "&::after": {
                    display: "none",
                  },
                  "&::before": {
                    display: "none",
                  },
                }}
                value={inputAns}
                onChange={(event) => setInputAns(event.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !inputDisabled) {
                    onSubmitAns();
                  }
                }}
                placeholder="Enter my answer ..."
              />
              {name && (
                <SuggestAnswerBtn
                  inputDisabled={!guidedQuestions[currentIndex]}
                  question={question}
                  guidedQuestion={guidedQuestions[currentIndex]}
                  onChoiceSuggest={(value) => {
                    onSubmitAns(value);
                  }}
                  name={name}
                />
              )}
              <IconButton disabled={inputDisabled} onClick={() => onSubmitAns()}>
                <SendIcon
                  sx={
                    inputDisabled
                      ? {}
                      : {
                          color: "#0A14FF",
                        }
                  }
                />
              </IconButton>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};
export default ChatWithBotPart2;
