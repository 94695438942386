import { IAnswerItem, parseParamUrl } from "@/common";
import NoDataComponent from "@/components/NoDataComponent";
import { EQuestionNumber, ROUTES } from "@/constants";
import { extractContent } from "@/helpers";
import { IApplicationState, editCustomAnswer, removeAnswerItem } from "@/store-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme } from "@mui/material";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ListInfoAnwer from "../ListInfoAnswer";

function AnswerTab() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [selectPart, setSelectPart] = useState<EQuestionNumber>(EQuestionNumber.QUESTION_PART_1);

  const listTopic = useSelector((state: IApplicationState) => state.listTopics);
  const listAnswerOfUser = useSelector((state: IApplicationState) => state.listAnswerOfUser);

  const listTopicExist = useMemo(() => {
    const valueTemp = listTopic?.data?.filter((item) => {
      const findItem = listAnswerOfUser?.data?.find((answer) => {
        const questionContent = extractContent(answer.question);
        return answer.topicId === item._id && questionContent?.split(" ")?.[0] === selectPart;
      });
      return !!findItem;
    });
    return valueTemp;
  }, [listAnswerOfUser?.data, listTopic?.data, selectPart]);

  const handleRemoveItemAnswer = (item: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeAnswerItem(item._id));
      }
    });
  };
  const handleClick = (value: IAnswerItem) => {
    const urlQuestion = parseParamUrl(ROUTES.ANSWER_QUESTION, { questionId: value.questionId });
    navigate(urlQuestion, { state: value });
    dispatch(editCustomAnswer(value.answer));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        overflowY: "auto",
        paddingRight: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "8px",
        }}
      >
        {Object.keys(EQuestionNumber).map((key, idx) => (
          <Box
            key={idx}
            sx={{
              cursor: "pointer",
              textTransform: "capitalize",
              border: "1px solid #C6C1C1",
              height: "35px",
              fontSize: "14px",
              paddingInline: "12px",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ...(selectPart === EQuestionNumber[key as keyof typeof EQuestionNumber]
                ? {
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                  }
                : {}),
            }}
            onClick={() => setSelectPart(EQuestionNumber[key as keyof typeof EQuestionNumber])}
          >
            {key?.split("_")?.slice(1, 3)?.join(" ")?.toLowerCase()}
          </Box>
        ))}
      </Box>
      {listTopicExist?.length ? (
        listTopicExist?.map((item) => (
          <Accordion
            sx={{
              borderRadius: "4px",
              height: "auto",
              margin: "0px !important",
            }}
            key={item._id}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                borderRadius: "8px",
                maxHeight: "48px !important",
                minHeight: "48px",
                height: "48px",
                boxShadow: "-1px -9px 10px rgba(0, 0, 0, 0.1)",
                "& .MuiAccordionSummary-content": {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: "15px",
                  marginRight: "20px",
                },
                "& h6": {
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                  fontSize: "16px",
                  fontWeight: "600",
                },
              }}
            >
              <Box
                component={"h6"}
                key={item._id}
                dangerouslySetInnerHTML={{
                  __html: `${item.name} (${
                    listAnswerOfUser.data?.filter((answer) => answer?.topicId === item._id).length
                  })`,
                }}
              ></Box>
            </AccordionSummary>
            <AccordionDetails
              sx={() => ({
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                maxHeight: "73vh",
                // [theme.breakpoints.up("sm")]: {
                //   maxHeight: "62vh",
                // },
                overflow: "auto",
              })}
            >
              <ListInfoAnwer
                idTopics={item._id}
                handleRemoveItemAnswer={handleRemoveItemAnswer}
                handleClick={handleClick}
                selectPart={selectPart}
              />
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <NoDataComponent />
      )}

      {/* <Box
        sx={{
          display: listAnswerOfUser?.data?.filter((item) => !item?.topicId || item?.topicId === ":topicId")?.length
            ? "block"
            : "none",
        }}
      >
        <Typography sx={{ marginBottom: "15px", fontWeight: "bold" }}>
          Please click answer as below and save edit to group with topics
        </Typography>
        <ListInfoAnwerOld handleRemoveItemAnswer={handleRemoveItemAnswer} handleClick={handleClick} />
      </Box> */}
    </Box>
  );
}

export default AnswerTab;
