import { Box, Button } from "@mui/material";
import { useState } from "react";
import RobotReadingQuestion from "./RobotReadingQuestion";
import Lottie from "lottie-react";
import robotAIJson from "@/assets/lottie-json/robot_ai.json";
import { IApplicationState } from "@/store-redux";
import { useSelector } from "react-redux";
import { extractContent, removeLeadingNumbersAndPeriods } from "@/helpers";
import SpeakingSpeed from "./SpeakingSpeed";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChooseSpeed from "./ChooseSpeed";

enum ETabSpeakingSpeed {
  ROBOT_READ_QUESTION = "ROBOT_READ_QUESTION",
  CHOOSE_SPEED = "CHOOSE_SPEED",
  SPEAKING_SPEECH = "SPEAKING_SPEECH",
  RESULT = "RESULT",
}

function TabSpeakingSpeed() {
  const detailQuestion = useSelector((state: IApplicationState) => state.detailItem);
  const cleanQuestion = removeLeadingNumbersAndPeriods(extractContent(detailQuestion.data?.name ?? ""));

  const [currentStep, setCurrentStep] = useState<ETabSpeakingSpeed>(ETabSpeakingSpeed.ROBOT_READ_QUESTION);
  const [speed, setSpeed] = useState(1);
  const handleGoBack = () => {
    switch (currentStep) {
      case ETabSpeakingSpeed.SPEAKING_SPEECH:
        setCurrentStep(ETabSpeakingSpeed.CHOOSE_SPEED);
        break;
      case ETabSpeakingSpeed.RESULT:
        setCurrentStep(ETabSpeakingSpeed.SPEAKING_SPEECH);
        break;
      case ETabSpeakingSpeed.CHOOSE_SPEED:
        setCurrentStep(ETabSpeakingSpeed.ROBOT_READ_QUESTION);
        break;
      default:
        break;
    }
  };
  return (
    <Box>
      {currentStep !== ETabSpeakingSpeed.ROBOT_READ_QUESTION && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleGoBack}
            sx={{
              minWidth: "68px",
              height: "40px",
              padding: "6px 16px",
              borderRadius: "4px",
              fontSize: "16px",
              fontWeight: 700,
              textTransform: "none",
              "& svg": {
                marginRight: "4px",
              },
            }}
          >
            <ArrowBackIcon />
            Back
          </Button>
        </Box>
      )}

      <Lottie
        animationData={robotAIJson}
        loop={true}
        style={{
          height: "300px",
        }}
      />
      {currentStep === ETabSpeakingSpeed.ROBOT_READ_QUESTION ? (
        <RobotReadingQuestion onNext={() => setCurrentStep(ETabSpeakingSpeed.CHOOSE_SPEED)} />
      ) : currentStep === ETabSpeakingSpeed.SPEAKING_SPEECH ? (
        <SpeakingSpeed onNext={() => setCurrentStep(ETabSpeakingSpeed.RESULT)} speed={speed} />
      ) : currentStep === ETabSpeakingSpeed.CHOOSE_SPEED ? (
        <ChooseSpeed
          speed={speed}
          setSpeed={setSpeed}
          onNext={() => setCurrentStep(ETabSpeakingSpeed.SPEAKING_SPEECH)}
        />
      ) : null}
    </Box>
  );
}

export default TabSpeakingSpeed;
