import { parseParamUrl } from "@/common";
import { EQuestionNumber, ROUTES } from "@/constants";
import { Box, Card, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import { Part1Icon } from "@/assets/icons/Part1Icon";
import { Part2Icon } from "@/assets/icons/Part2Icon";
import { Part3Icon } from "@/assets/icons/Part3Icon";

function LearnPage() {
  const navigate = useNavigate();
  const handleNavigate = (type: EQuestionNumber) => {
    const url = parseParamUrl(ROUTES.LEARN_TOPIC_QUESTION, undefined, {
      "type-question": type,
    });
    navigate(url);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: "50px",
        marginLeft: "32px",
        "& .MuiCard-root": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "20px",
          color: "white",
          fontWeight: "bold",
          cursor: "pointer",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "32px",
        }}
      >
        <Typography
          component={"h3"}
          sx={{
            fontSize: "20px",
            fontWeight: "600",
            marginBottom: "16px",
          }}
        >
          IELTS Speaking
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "24px",
            "&>div": {
              borderRadius: "20px",
              display: "flex",
              flexDirection: "column",
              width: "22vw",
              padding: "32px 24px",
            },
            "& .title": {
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              "& h3": {
                fontWeight: "600",
                fontSize: "24px",
              },
              "& svg": {
                fontSize: "20px",
              },
            },
            "& .sub-title": {
              width: "100%",
              fontSize: "14px",
              fontWeight: "400",
            },
          }}
        >
          <Card
            sx={{
              background: "#0068FF",
            }}
            onClick={() => {
              handleNavigate(EQuestionNumber.QUESTION_PART_1);
            }}
          >
            <Box className="title">
              <Typography component={"h3"}>Part 1</Typography>
              <EastIcon />
            </Box>
            <Typography component={"span"} className="sub-title">
              Introduction & Interview
            </Typography>
            <Part1Icon />
          </Card>
          <Card
            sx={{
              background: "#CF75FF",
            }}
            onClick={() => {
              navigate(ROUTES.LEARN_PART_2);
            }}
          >
            <Box className="title">
              <Typography component={"h3"}>Part 2</Typography>
              <EastIcon />
            </Box>
            <Typography component={"span"} className="sub-title">
              Long Turn
            </Typography>
            <Part2Icon />
          </Card>
          <Card
            sx={{
              background: "#FADB39",
            }}
            onClick={() => {
              handleNavigate(EQuestionNumber.QUESTION_PART_3);
            }}
          >
            <Box
              className="title"
              sx={{
                color: "#000",
              }}
            >
              <Typography component={"h3"}>Part 3</Typography>
              <EastIcon />
            </Box>
            <Typography
              component={"span"}
              className="sub-title"
              sx={{
                color: "#000",
              }}
            >
              Discussion
            </Typography>
            <Part3Icon />
          </Card>
        </Box>
      </Box>
    </Box>
  );
}

export default LearnPage;
