import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import { TabContext, TabList } from "@mui/lab";
import { Box, Button, IconButton, Tab, Typography } from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useLocation } from "react-router-dom";
import SunEditor from "suneditor-react";
import { extractContent, genCategoryAspect, refinePrompt } from "../../../../../helpers";
import { AiBoxTab, IApplicationState, IItems, editCustomAnswer, getWordSearch } from "../../../../../store-redux";
import ListAnswer from "../../GuidedQuestionPage/components/ListAnswer";
import ListIdeaAnswerBox from "../../GuidedQuestionPage/components/ListIdeaAnswerBox";
import ListSuggest from "../../IdeaPage/components/ListSuggest";
import WordSearch from "../../WordSearch";
import PracticeModal from "./PracticeModal";
import TestingSpeakingModal from "./TestingSpeakingModal";
import TabAssistantAI from "./TabAssistantAI";
import TabSuggestion from "./TabSuggestion";

interface ICustomAnswerProps {
  isPartTwo?: boolean;
  question?: IItems;
  guidedQuestions?: any[];
  answersByAspect?: any;
  name?: string;
  boldIndexes: number[];
  setBoldIndexes: React.Dispatch<React.SetStateAction<number[]>>;
}

function CustomAnswer(props: ICustomAnswerProps) {
  const { boldIndexes, setBoldIndexes } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const isFromProfile = Boolean(location.state);
  const editCustomAnswerContent = useSelector((state: IApplicationState) => state.editCustomAnswer);
  const listAllAspectsState = useSelector((state: IApplicationState) => state.listAllAspects);
  const listCategories = useSelector((state: IApplicationState) => state.listCategories);
  const [tabValue, setTabValue] = useState("1");
  const [contentGeneratorConnector, setContentGeneratorConnector] = useState("");
  const [guidedQuestionsSelected, setGuidedQuestionsSelected] = useState([]);
  const [showLeftPanel, setShowLeftPanel] = useState(true);
  const [openTest, setOpenTest] = useState(false);
  const detailQuestion = useSelector((state: IApplicationState) => state.detailItem);
  const [openPractice, setOpenPractice] = useState(false);

  const handleAddItemCustom = (idea: IItems) => {
    const contentFormat = extractContent(idea.description ?? "").replace(/^.*"(.*?)"[^"]*$/, "$1");
    if (idea && editCustomAnswerContent.data?.indexOf(contentFormat) === -1) {
      let singleNameTag = "☆";
      if (idea.locationIdea) {
        singleNameTag = idea.locationIdea;
      } else {
        const findAspect = listAllAspectsState.data.find((item) => item._id === idea.aspectId);
        if (findAspect) {
          const findCategory = listCategories.data?.find((item) => item._id === findAspect.parentId);
          if (findCategory) {
            singleNameTag = genCategoryAspect(findCategory, findAspect);
          }
        }
      }

      dispatch(editCustomAnswer(editCustomAnswerContent.data + " " + `(${singleNameTag}) ` + contentFormat));
    }
  };

  const handleEditorChange = (content: string) => {
    const contentFormat = extractContent(content);
    dispatch(editCustomAnswer(contentFormat));
  };

  useEffect(() => {
    const editorWrapperEl = document.getElementById("editorWrapper");
    const handleMouseUp = () => {
      const selectedText = (window?.getSelection()?.toString() ?? "").trim();
      if (selectedText !== "") {
        getWordSearchFn("");
        debounceGetWordSearchFn(selectedText);
      }
    };
    if (editorWrapperEl) {
      const editorEl = editorWrapperEl.querySelector(".sun-editor-editable");
      editorEl?.addEventListener("mouseup", handleMouseUp);
    }
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const getWordSearchFn = (word: string) => {
    dispatch(getWordSearch(word));
  };
  const debounceGetWordSearchFn = useCallback(_.debounce(getWordSearchFn, 1000), []);

  // SUPPORT AI
  const [boxTabActive, setBoxTabActive] = useState<null | AiBoxTab>(AiBoxTab.contentGeneratorConnector);

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const onHandleTest = () => {
    setOpenTest(true);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={() => ({
          height: "calc(100vh - 110px)",
          overflow: "hidden",
          display: "flex",
          width: "100%",
          gap: "15px",
          marginTop: "16px",
          borderTop: "1px solid #e1e1e1",
          borderRadius: "4px",
          position: "relative",
          "& .content_box": {
            margin: "0px",
            height: "100%",
          },
          "& .assistant_box": {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            minHeight: "calc(50vh + 28px)",
            padding: "16px",
            height: "100%",
            position: "relative",
            "& button": {
              textTransform: "none",
            },
          },
        })}
      >
        <PanelGroup direction="horizontal">
          <Panel
            defaultSize={35}
            style={{
              display: showLeftPanel ? "block" : "none",
            }}
          >
            <Box
              className="content_box"
              sx={{
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
              }}
            >
              <Box
                sx={(theme) => ({
                  display: "block",
                  borderRadius: "0px",
                  borderBottom: "none",
                  // maxHeight: "240px",
                  "& .MuiAccordionSummary-root": {
                    "&.Mui-expanded": {
                      borderBottom: "1px solid #e1e1e1",
                    },
                  },
                  "& .MuiCollapse-root": {
                    maxHeight: "75vh",
                    [theme.breakpoints.up("sm")]: {
                      maxHeight: "71vh",
                    },
                  },
                  "& .MuiAccordionSummary-content": {
                    fontWeight: "bold",
                    marginBottom: "20px",
                  },
                  "& .MuiAccordionSummary-expandIconWrapper": {
                    "& svg": {
                      fill: "black",
                    },
                  },
                  "& .MuiAccordionDetails-root": {
                    "& div": {},
                  },
                })}
              >
                {props?.isPartTwo ? (
                  <ListAnswer
                    guidedQuestions={props.guidedQuestions}
                    answersByAspect={props.answersByAspect}
                    question={props.question}
                    name={props.name}
                    onGenerateAnswer={(content) => {
                      dispatch(editCustomAnswer(content));
                    }}
                    handleClickGuidedQuestion={(item) => {
                      setGuidedQuestionsSelected((prev: any) =>
                        prev.find((itemG: any) => itemG._id === item._id) ? prev : [...prev, item]
                      );
                    }}
                  />
                ) : (
                  <TabContext value={tabValue}>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        TabIndicatorProps={{
                          sx: {
                            height: "2px",
                          },
                        }}
                        sx={(theme) => ({
                          "& button": {
                            textTransform: "none",
                            color: theme.palette.secondary.main,
                          },
                          "& .Mui-selected": {
                            fontWeight: "bold",
                            color: theme.palette.secondary.main,
                          },
                        })}
                      >
                        {[
                          { id: "1", label: "My Ideas" },
                          { id: "2", label: "Suggestion" },
                        ].map((tab) => (
                          <Tab label={tab.label} value={tab.id} key={tab.id} />
                        ))}
                      </TabList>
                      <Box>
                        <IconButton onClick={() => setShowLeftPanel(false)}>
                          <ArrowBackIcon htmlColor="#0030f8" />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box sx={{}}>
                      {tabValue === "1" && (
                        <ListSuggest
                          dataOfAnswer={isFromProfile ? JSON.parse(location.state?.idea) : null}
                          handleClickIdea={handleAddItemCustom}
                        />
                      )}
                      {tabValue === "2" && <TabSuggestion setBoxTabActive={setBoxTabActive} />}
                    </Box>
                  </TabContext>
                )}
              </Box>
            </Box>
          </Panel>
          <PanelResizeHandle
            style={{
              width: "5px",
              backgroundColor: "#e1e1e1",
            }}
          />
          <Panel defaultSize={65}>
            <Box>
              <Box className="assistant_box" sx={{}}>
                <Box
                  sx={{
                    width: "100%",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      borderBottom: "1px solid #D9D9D9",
                      paddingBottom: "16px",
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      My Answer
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        flexWrap: "wrap",
                        marginTop: "8px",
                        "& button": {
                          minWidth: "68px",
                          height: "40px",
                          padding: "6px 16px",
                          borderRadius: "4px",
                          fontSize: "16px",
                          fontWeight: 700,
                          textTransform: "none",
                          "& svg": {
                            marginRight: "4px",
                          },
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        {editCustomAnswerContent.data !== "" ? (
                          <Button variant="contained" onClick={() => setOpenPractice(true)} color="info">
                            <DirectionsWalkIcon />
                            Practice
                          </Button>
                        ) : null}
                        <Button variant="contained" onClick={onHandleTest} color="warning">
                          <RecordVoiceOverIcon />
                          Test
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "8px",
                  }}
                >
                  <Box
                    sx={{
                      maxHeight: "calc(100vh - 180px)",
                      overflowY: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        marginBlock: "16px",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "14px",
                          marginBottom: "8px",
                        }}
                      >
                        AI Assistant
                      </Typography>
                      <TabAssistantAI
                        isPartTwo={props.isPartTwo}
                        contentGeneratorConnector={contentGeneratorConnector}
                        setBoxTabActive={setBoxTabActive}
                        setContentGeneratorConnector={setContentGeneratorConnector}
                        question={refinePrompt(extractContent(props.question?.name ?? ""))}
                      />
                    </Box>
                    <Box
                      sx={{
                        maxHeight: "calc(100vh - 180px)",
                        width: "100%",
                        overflowY: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          flex: 1,
                          "& .sun-editor": {
                            height: "100%",
                            borderRadius: "8px",
                          },
                          "& .se-wrapper": {
                            height: "calc(100% - 12px)!important",
                            overflow: "auto",
                            borderRadius: "8px",
                            "& .se-wrapper-inner": {
                              height: "auto !important",
                              textAlign: "justify",
                              fontSize: "16px !important",
                            },
                          },
                          "& .se-resizing-bar": {
                            display: "none",
                          },
                        }}
                        id="editorWrapper"
                      >
                        {guidedQuestionsSelected.length > 0 && props.isPartTwo ? (
                          <ListIdeaAnswerBox
                            question={props.question}
                            name={props.name}
                            guidedQuestionsSelected={guidedQuestionsSelected}
                            answersByAspect={props.answersByAspect}
                            removeGuidedQuestion={(id) => {
                              setGuidedQuestionsSelected((prev) => prev.filter((itemG: any) => itemG._id !== id));
                            }}
                            onAddConnectorDone={(content) => {
                              setGuidedQuestionsSelected([]);
                              dispatch(editCustomAnswer(content));
                            }}
                          />
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              gap: "16px",
                              "&>div": {
                                flex: 1,
                              },
                            }}
                          >
                            <SunEditor
                              onChange={handleEditorChange}
                              hideToolbar
                              setContents={editCustomAnswerContent.data.toString()}
                            />
                            {contentGeneratorConnector ? (
                              <Box
                                sx={{
                                  position: "relative",
                                  flex: "1",
                                  border: "1px solid #dadada",
                                  padding: "16px",
                                  borderRadius: "8px",
                                }}
                              >
                                <Box
                                  sx={{
                                    textAlign: "justify",
                                    paddingRight: "5px",
                                    fontSize: "16px",
                                  }}
                                >
                                  {contentGeneratorConnector?.slice(
                                    contentGeneratorConnector.indexOf("(") === -1
                                      ? 0
                                      : contentGeneratorConnector.indexOf("(")
                                  )}
                                </Box>
                                <Box
                                  sx={{ marginBlock: "15px", display: "flex", justifyContent: "center", gap: "20px" }}
                                >
                                  <Button
                                    onClick={() => {
                                      dispatch(editCustomAnswer(contentGeneratorConnector));
                                      setTimeout(() => {
                                        setContentGeneratorConnector("");
                                      }, 100);
                                    }}
                                    variant="outlined"
                                    color="success"
                                  >
                                    Accept
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      setContentGeneratorConnector("");
                                    }}
                                    variant="outlined"
                                    color="warning"
                                  >
                                    Cancel
                                  </Button>
                                </Box>
                              </Box>
                            ) : null}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      borderLeft: "1px solid #dadada",
                      paddingLeft: "16px",
                      minWidth: "300px",
                      maxWidth: "500px",
                      display: boxTabActive === AiBoxTab.wordSearch ? "block" : "none",
                      maxHeight: "calc(100% - 50px)",
                      overflowY: "auto",
                    }}
                  >
                    <WordSearch
                      showWordSearch={() => {
                        setBoxTabActive(AiBoxTab.wordSearch);
                      }}
                      hideWordSearch={() => {
                        setBoxTabActive(AiBoxTab.contentGeneratorConnector);
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Panel>
        </PanelGroup>
        {!showLeftPanel ? (
          <Box
            sx={(theme) => ({
              position: "absolute",
              left: 0,
              top: 35,
              height: 30,
              width: 30,
              backgroundColor: theme.palette.primary.main,
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              "& svg": {
                color: "white",
              },
            })}
            onClick={() => setShowLeftPanel(true)}
          >
            <ArrowForwardIcon />
          </Box>
        ) : null}
      </Box>

      <TestingSpeakingModal
        openPractice={openTest}
        onClose={() => setOpenTest(false)}
        dataQuestion={
          isFromProfile
            ? {
                name: location.state?.question,
                _id: location.state?.questionId,
              }
            : detailQuestion?.data
        }
      />

      <PracticeModal
        open={openPractice}
        handleClose={() => setOpenPractice(false)}
        setBoldIndexes={setBoldIndexes}
        boldIndexes={boldIndexes}
      />
    </Box>
  );
}

export default CustomAnswer;
