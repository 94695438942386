import { IApplicationState, IItems, parseParamUrl, removeIntoCart } from "@/store-redux";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "@/constants";
import { useState } from "react";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import ChatWithBot from "./ChatWithBot";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import KeywordToIdea from "./KeywordToIdea";
const CHAT_TYPE_MORE_IDEAS_WITH_AI = "more_ideas_with_ai";
const CHAT_TYPE_KEYWORD_TO_IDEA = "keyword_to_idea";
const MyIdeaPath = () => {
  const detailQuestion = useSelector((state: IApplicationState) => state.detailItem);
  const { questionId } = useParams();
  const [searchParams] = useSearchParams();
  const typeQuestion = searchParams.get("type-question");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listMyIdeaPath = useSelector((state: IApplicationState) => state.myIdeaPath);
  const [chatType, setChatType] = useState<string | null>(null);
  const onRemoveIdea = (idea: IItems) => {
    dispatch(removeIntoCart(idea));
  };
  return (
    <Box
      sx={
        {
          //  boxShadow: "0px 4px 10px 0px #00000026"
        }
      }
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 10px",
          height: "50px",
          marginTop: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              marginRight: "5px",
            }}
          >
            My Idea Path
          </Typography>
          {listMyIdeaPath.data?.length > 0 && (
            <Box
              sx={{
                background: "#0A14FF",
                color: "#fff",
                borderRadius: "50%",
                padding: "0 5px",
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "18px",
              }}
            >
              {listMyIdeaPath.data?.length}
            </Box>
          )}
        </Box>
        {listMyIdeaPath.data?.length > 0 && (
          <Button
            variant="contained"
            sx={{
              background: "#0A14FF",
              color: "#fff",
              textTransform: "unset",
              height: "37px",
              fontWeight: "bold",
              borderRadius: "5px",
            }}
            onClick={() => {
              const url = parseParamUrl(ROUTES.ANSWER_QUESTION.replace(":questionId", questionId ?? ""), undefined, {
                "type-question": typeQuestion,
              });
              navigate(url);
            }}
          >
            Customized
          </Button>
        )}
      </Box>
      <Box
        sx={{
          height: "1px",
          backgroundColor: "#D9D9D9",
          marginTop: "8px",
          marginBottom: "10px",
        }}
      />
      <Box
        sx={{
          height: "calc(100vh - 160px)",
          overflowY: "auto",
          padding: "0 10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              color: "#6A6969",
              display: "flex",
              fontSize: "12px",
              alignItems: "center",
              cursor: "pointer",
              padding: "5px 10px",
              borderRadius: "2px",
              ...(chatType === CHAT_TYPE_KEYWORD_TO_IDEA
                ? {
                    backgroundColor: "#fed8d8",
                    color: "#c44141",
                  }
                : {}),
            }}
            onClick={() => {
              setChatType((prev) => (prev !== CHAT_TYPE_KEYWORD_TO_IDEA ? CHAT_TYPE_KEYWORD_TO_IDEA : null));
            }}
          >
            <WbSunnyIcon
              sx={{
                marginRight: "5px",
              }}
            />
            Keyword to Idea
          </Box>
          <Box
            sx={{
              color: "#6A6969",
              display: "flex",
              fontSize: "12px",
              alignItems: "center",
              cursor: "pointer",
              padding: "5px 10px",
              borderRadius: "2px",
              ...(chatType === CHAT_TYPE_MORE_IDEAS_WITH_AI
                ? {
                    backgroundColor: "#F3F3FF",
                    color: "#0A14FF",
                  }
                : {}),
            }}
            onClick={() => {
              setChatType((prev) => (prev !== CHAT_TYPE_MORE_IDEAS_WITH_AI ? CHAT_TYPE_MORE_IDEAS_WITH_AI : null));
            }}
          >
            <TipsAndUpdatesIcon
              sx={{
                marginRight: "5px",
              }}
            />
            More ideas with AI
          </Box>
        </Box>
        {chatType === CHAT_TYPE_MORE_IDEAS_WITH_AI && (
          <Box>
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#6A6969",
                fontSize: "14px",
                marginBottom: "10px",
              }}
            >
              Chat with Bot
            </Typography>
            <ChatWithBot />
          </Box>
        )}
        {chatType === CHAT_TYPE_KEYWORD_TO_IDEA && (
          <Box>
            <Typography
              sx={{
                fontWeight: "bold",
                color: "#6A6969",
                fontSize: "14px",
                marginBottom: "10px",
              }}
            >
              Chat with Bot
            </Typography>
            <KeywordToIdea question={detailQuestion?.data} />
          </Box>
        )}
        <Typography
          sx={{
            color: "#6A6969",
            fontSize: "14px",
          }}
        >
          Ý tưởng đã chọn
        </Typography>
        <Box>
          {listMyIdeaPath.data?.map((idea, index) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  padding: "5px 0",
                  ...(index !== listMyIdeaPath.data.length - 1 ? { borderBottom: "1px solid #D9D9D9" } : {}),
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                  }}
                >
                  <Box
                    sx={{
                      textAlign: "left",
                      fontSize: "14px!important",
                      fontWeight: "bold",
                      "& *": {
                        fontSize: "14px!important",
                      },
                    }}
                    className="title"
                    dangerouslySetInnerHTML={{ __html: idea.name?.replace(/\d+\.\s*&nbsp;/, "") || "" }}
                  />
                  <Box
                    sx={{
                      textAlign: "left",
                      fontSize: "13px",
                    }}
                    className="description"
                    dangerouslySetInnerHTML={{ __html: idea.description || "" }}
                  />
                </Box>
                <IconButton
                  sx={{
                    marginLeft: "5px",
                  }}
                  onClick={() => onRemoveIdea(idea)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
export default MyIdeaPath;
