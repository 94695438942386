import { cleanQuestion } from "@/helpers";
import { IApplicationState } from "@/store-redux";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";

interface IProps {
  onNext: () => void;
}

function RobotReadingQuestion({ onNext }: IProps) {
  const detailQuestion = useSelector((state: IApplicationState) => state.detailItem);
  const question = cleanQuestion(detailQuestion.data?.name ?? "");

  const onStart = () => {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(question);
    utterance.lang = "en-US";
    synth.speak(utterance);

    utterance.onend = function () {
      onNext();
    };
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button
        variant="contained"
        onClick={onStart}
        sx={{
          minWidth: "68px",
          height: "40px",
          padding: "6px 16px",
          borderRadius: "4px",
          fontSize: "16px",
          fontWeight: 700,
          textTransform: "none",
          "& svg": {
            marginRight: "4px",
          },
        }}
      >
        Start
      </Button>
    </Box>
  );
}

export default RobotReadingQuestion;
