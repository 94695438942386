import { Box, Button, CircularProgress, IconButton, TextField, Typography } from "@mui/material";
import LogoIcon from "./../../assets/logo.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, IApplicationState, showMessage } from "@/store-redux";
import { useUpdateInfoUser } from "@/services-react-query/queries";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/constants";
import CloseIcon from "@mui/icons-material/Close";
import AbilityIcon_01 from "../../assets/icons/ability_01.png";
import AbilityIcon_02 from "../../assets/icons/ability_02.png";
import AbilityIcon_03 from "../../assets/icons/ability_03.png";
import AbilityIcon_04 from "../../assets/icons/ability_04.png";
import AbilityIcon_05 from "../../assets/icons/ability_05.png";
import AbilityIcon_06 from "../../assets/icons/ability_06.png";
import AbilityIcon_07 from "../../assets/icons/ability_07.png";
import AbilityIcon_08 from "../../assets/icons/ability_08.png";
import AbilityIcon_09 from "../../assets/icons/ability_09.png";
import { capitalizeFirstLetter } from "@/helpers";
enum EJobType {
  hoc_sinh = "hoc_sinh",
  sinh_vien = "sinh_vien",
  di_lam = "di_lam",
}

enum EAbilityType {
  noi_tam = "noi_tam",
  ngon_ngu = "ngon_ngu",
  the_chat = "the_chat",
  tuong_tac_giao_tiep = "tuong_tac_giao_tiep",
  triet_hoc = "triet_hoc",
  toan_hoc_logic = "toan_hoc_logic",
  am_nhac_thinh_giac = "am_nhac_thinh_giac",
  tu_nhien = "tu_nhien",
  khong_gian_thi_giac = "khong_gian_thi_giac",
}

enum EFuturePlanType {
  lap_gia_dinh = "lap_gia_dinh",
  thang_tien_nghe_nghiep = "thang_tien_nghe_nghiep",
  di_du_hoc = "di_du_hoc",
  bat_dau_kinh_doanh = "bat_dau_kinh_doanh",
}

const listJobType = [
  {
    code: EJobType.hoc_sinh,
    name: "Tôi là học sinh",
  },
  {
    code: EJobType.sinh_vien,
    name: "Tôi đang là sinh viên",
  },
  {
    code: EJobType.di_lam,
    name: "Tôi đã đi làm",
  },
];

const listAbilityType = [
  {
    icon: AbilityIcon_01,
    code: EAbilityType.khong_gian_thi_giac,
    name: "không gian & thị giác",
    subName: "giỏi quan sát, xoay xở với các loại đồ vật và không gian",
  },
  {
    icon: AbilityIcon_02,
    code: EAbilityType.tu_nhien,
    name: "tự nhiên",
    subName: "nhạy cảm và hiểu biết về các yêu tố trong thiên nhiên",
  },
  {
    icon: AbilityIcon_03,
    code: EAbilityType.am_nhac_thinh_giac,
    name: "âm nhạc & thính giác",
    subName: "nhạy cảm với âm thanh, thẩm âm tốt",
  },
  {
    icon: AbilityIcon_04,
    code: EAbilityType.toan_hoc_logic,
    name: "toán học & logic",
    subName: "khả năng giải toán và năng lực lập luận mạnh mẽ",
  },
  {
    icon: AbilityIcon_05,
    code: EAbilityType.triet_hoc,
    name: "triết học",
    subName: "nhạy cảm với những câu hỏi tầm cỡ nhân loại (sự sống, cái chết, lý do tồn tại...)",
  },
  {
    icon: AbilityIcon_06,
    code: EAbilityType.tuong_tac_giao_tiep,
    name: "tương tác & giao tiếp",
    subName: "giao tiếp hiệu quả, dễ gây cảm hứng",
  },
  {
    icon: AbilityIcon_07,
    code: EAbilityType.the_chat,
    name: "thể chất",
    subName: "giỏi chế tạo, xây dụng kỹ năng cử động cơ thể chuẩn xác",
  },
  {
    icon: AbilityIcon_08,
    code: EAbilityType.ngon_ngu,
    name: "ngôn ngữ",
    subName: "nắm bắt từ ngữ nhanh, khả năng diễn đạt và thuyết phục tốt",
  },
  {
    icon: AbilityIcon_09,
    code: EAbilityType.noi_tam,
    name: "nội tâm",
    subName: "thấu hiểu bản thân muốn gì, khả năng tập trung cao",
  },
];

const listFuturePlanType = [
  {
    code: EFuturePlanType.lap_gia_dinh,
    name: "Lập gia đình",
  },
  {
    code: EFuturePlanType.thang_tien_nghe_nghiep,
    name: "Thăng tiến Nghề nghiệp",
  },
  {
    code: EFuturePlanType.di_du_hoc,
    name: "Đi du học/ học cao học",
  },
  {
    code: EFuturePlanType.bat_dau_kinh_doanh,
    name: "Bắt đầu kinh doanh",
  },
];
const PersonalizedPage = ({ isTab }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(isTab ? 1 : 0);
  const [personalization, setPersonalization] = useState({});
  const nbSteps = 3;
  const currentUser = useSelector((state: IApplicationState) => state.currentUser);
  const {
    mutate: updateInfoUser,
    isLoading,
    isSuccess,
  } = useUpdateInfoUser({
    onSuccess: () => {
      dispatch(getCurrentUser());
      dispatch(showMessage("Save info success", "success"));
    },
  });

  useEffect(() => {
    if (!_.isEmpty(currentUser.data?.personalization)) {
      if (isSuccess && !isTab) {
        navigate(ROUTES.LEARN);
      }
      setPersonalization(currentUser.data?.personalization);
    }
  }, [currentUser, isSuccess]);

  const infoSteps = [
    {
      title: "Nghề nghiệp của bạn",
      subTitle: "Hiện bạn đang là sinh viên hay bạn đã là người đi làm",
    },
    {
      title: "Khả năng của bạn",
      subTitle: "Tính cách sẽ ảnh hướng đến những gợi ý của tôi dành cho bạn ",
    },
    {
      title: "Kế hoạch tương lai",
      subTitle: "This will helps us find you some starting ideas and examples",
    },
  ];

  const onSave = () => {
    updateInfoUser({
      email: currentUser.data?.email,
      personalization,
    });
  };

  if (step !== 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          ...(isTab ? {} : { height: "100vh", width: "100vw" }),
        }}
      >
        {!_.isEmpty(currentUser.data?.personalization) && !isTab && (
          <IconButton
            sx={{
              position: "absolute",
              right: "10px",
              top: "10px",
            }}
            onClick={() => {
              navigate(ROUTES.LEARN);
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        <Box
          sx={{
            display: "flex",
            marginBottom: "40px",
          }}
        >
          {new Array(nbSteps).fill(1).map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  margin: "0 5px",
                  height: "8px",
                  width: "80px",
                  backgroundColor: "#D9D9D9",
                  borderRadius: "4px",
                  cursor: "pointer",
                  ...(step === index + 1
                    ? {
                        backgroundColor: "#0A14FF",
                      }
                    : {}),
                  "&:hover": {
                    backgroundColor: "#0A14FF",
                  },
                }}
                onClick={() => setStep(index + 1)}
              ></Box>
            );
          })}
        </Box>
        <Box
          sx={{
            width: 700,
            maxWidth: "calc(100vw - 40px)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Box
              sx={{
                flex: 1,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: 24,
                }}
              >
                {infoSteps[step - 1].title}
              </Typography>
              <Typography>{infoSteps[step - 1].subTitle}</Typography>
            </Box>
            {step < 3 && (
              <Box
                sx={{
                  cursor: "pointer",
                  color: "#313131",
                  "&:hover": {
                    fontWeight: "bold",
                  },
                }}
                onClick={() => setStep((prev) => prev + 1)}
                disabled={isLoading}
              >
                Bỏ qua
              </Box>
            )}
          </Box>
          {step === 1 && (
            <Box
              sx={{
                display: "flex",
              }}
            >
              {listJobType.map((job) => {
                return (
                  <Box
                    sx={{
                      border: "1px solid #C6C1C1",
                      color: "#313131",
                      margin: "10px",
                      padding: "20px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      flex: 1,
                      ...(personalization?.job === job.code
                        ? { border: "1px solid #0A14FF" }
                        : {
                            "&:hover": {
                              backgroundColor: "#ccc",
                            },
                          }),
                    }}
                    onClick={() => setPersonalization((prev) => ({ ...prev, job: job.code }))}
                  >
                    {job.name}
                  </Box>
                );
              })}
            </Box>
          )}
          {step === 2 && (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "auto auto auto",
                gap: "10px",
              }}
            >
              {listAbilityType.map((ability) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      border: "1px solid #C6C1C1",
                      color: "#313131",
                      padding: "10px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      "& img": {
                        width: 24,
                        height: 24,
                      },
                      "& i": {
                        fontSize: "12px",
                      },
                      ...(Array.isArray(personalization?.ability) && personalization?.ability.includes(ability.code)
                        ? { border: "1px solid #0A14FF" }
                        : {
                            "&:hover": {
                              backgroundColor: "#ccc",
                            },
                          }),
                    }}
                    onClick={() =>
                      setPersonalization((prev) => ({
                        ...prev,
                        ability: Array.isArray(prev.ability)
                          ? prev.ability.includes(ability.code)
                            ? prev.ability.filter((a) => a !== ability.code)
                            : [...prev.ability, ability.code]
                          : [ability.code],
                      }))
                    }
                  >
                    <img src={ability.icon} />
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        marginBottom: "5px",
                      }}
                    >
                      {capitalizeFirstLetter(ability.name)}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      {capitalizeFirstLetter(ability.subName)}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}
          {step === 3 && (
            <>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                {listFuturePlanType.map((futurePlan) => {
                  return (
                    <Box
                      sx={{
                        // backgroundColor: "#F7F7F7",
                        border: "1px solid #C6C1C1",
                        color: "#313131",
                        margin: "10px",
                        padding: "20px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        ...(personalization?.futurePlan === futurePlan.code
                          ? { border: "1px solid #0A14FF" }
                          : {
                              "&:hover": {
                                backgroundColor: "#ccc",
                              },
                            }),
                      }}
                      onClick={() => setPersonalization((prev) => ({ ...prev, futurePlan: futurePlan.code }))}
                    >
                      {futurePlan.name}
                    </Box>
                  );
                })}
              </Box>
              <TextField
                autoFocus
                multiline
                sx={{
                  backgroundColor: "#F7F7F7",
                  margin: "10px",
                  width: "calc(100% - 20px)",
                  "& .MuiInputBase-root": {
                    border: "none",
                  },
                  "& textarea": {
                    fontSize: "0.875rem",
                  },
                }}
                variant="outlined"
                size="small"
                rows={3}
                placeholder="Kế hoạch khác, hãy cho chúng tôi biết thêm nhé..."
                value={personalization.futurePlanText ?? ""}
                onChange={(e) =>
                  setPersonalization((prev) => ({
                    ...prev,
                    futurePlanText: e.target.value,
                  }))
                }
              />
            </>
          )}
          <Box
            sx={{
              padding: "10px 0",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {step > 1 ? (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  textTransform: "unset",
                  fontWeight: "bold",
                  backgroundColor: "#F7F7F7",
                  color: "#313131",
                  "&:hover": {
                    backgroundColor: "#ccc",
                    color: "#313131",
                  },
                }}
                onClick={() => setStep((prev) => prev - 1)}
                disabled={isLoading}
              >
                Quay lại
              </Button>
            ) : (
              <div></div>
            )}
            <Button
              variant="contained"
              color="primary"
              sx={{
                textTransform: "unset",
                fontWeight: "bold",
              }}
              onClick={() => {
                if (step === 3) {
                  onSave();
                } else {
                  setStep((prev) => prev + 1);
                }
              }}
              disabled={isLoading}
            >
              {isLoading && <CircularProgress />}
              {step === 3 ? "Hoàn thành" : "Tiếp tục"}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        maxWidth: 1000,
        margin: "auto",
      }}
    >
      {!_.isEmpty(currentUser.data?.personalization) && !isTab && (
        <IconButton
          sx={{
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
          onClick={() => {
            navigate(ROUTES.LEARN);
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <Box
        sx={{
          "& img": {
            width: 240,
            height: 240,
          },
        }}
      >
        <img src={LogoIcon} />
      </Box>
      <Box
        sx={{
          paddingLeft: "40px",
        }}
      >
        <Typography
          sx={{
            display: "flex",
            flexDirection: "column",
            fontSize: 36,
            fontWeight: "bold",
            lineHeight: 1.1,
          }}
        >
          Chào mừng đến với <b />
          FongFluency
        </Typography>
        <Typography
          sx={{
            margin: "20px 0 40px 0",
          }}
        >
          Ứng dụng giúp bạn tự tin đạt 8.0 trong phần thi IETLS Speaking. Chúng tôi muốn hiểu 1 chút về bạn để đưa cho
          bạn 1 lộ trình học tối ưu nhất
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            textTransform: "unset",
            fontWeight: "bold",
          }}
          onClick={() => setStep(1)}
        >
          OK! Bắt đầu thôi
        </Button>
      </Box>
    </Box>
  );
};

export default PersonalizedPage;
