import { ENDPOINTS, getApiUrl, IItems, Requests } from "@/common";
import { Box, Button, CircularProgress, IconButton, Input } from "@mui/material";
import { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { extractContent, randomStringFromDatetime, refinePrompt } from "@/helpers";
import ListIdeaAI from "./ListIdeaAI";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/constants";

interface KeywordToIdeaProps {
  isPartTwo: boolean;
  question: IItems | null;
}
const KeywordToIdea = ({ question, isPartTwo }: KeywordToIdeaProps) => {
  const navigate = useNavigate();
  const [inputAns, setInputAns] = useState("");
  const [idea, setIdea] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const onSubmitAns = async () => {
    setIsLoading(true);

    try {
      const apiUrl = getApiUrl(isPartTwo ? ENDPOINTS.KEYWORD_TO_IDEA_PART_2 : ENDPOINTS.KEYWORD_TO_IDEA);
      const res = await Requests.post(apiUrl, {
        question: refinePrompt(extractContent(question?.name ?? "")),
        keyword: inputAns,
      });
      if (isPartTwo) {
        if (res.data?.result) {
          setIdea((res.data?.result ?? "").toString());
        }
      } else {
        if (res.data?.result?.length) {
          const item = res.data?.result[0];
          setIdea({
            genByAI: true,
            _id: randomStringFromDatetime(),
            name: `${item.title} (${item.detail})`,
            description: item.example_answer,
            locationIdea: "",
          });
        }
      }
    } catch (e) {}
    setIsLoading(false);
  };
  return (
    <Box
      sx={
        isPartTwo
          ? {
              overflowY: "auto",
              maxHeight: "calc(100vh - 151px)",
            }
          : {}
      }
    >
      <Box
        sx={{
          backgroundColor: "#F3F3FF",
          borderRadius: "5px",
          padding: "10px",
          "& p": {
            color: "#313131",
            fontSize: "14px",
          },
        }}
      >
        <p>
          Xin chào, có vẻ là không có idea nào phù hợp với bạn. Không sao, tôi sẽ giúp bạn tạo idea cho câu hỏi trên
          nhé!
          <br />
        </p>
        <Box
          sx={{
            fontWeight: "bold",
            fontSize: "16px",
          }}
          dangerouslySetInnerHTML={{ __html: question?.name ?? "" }}
        ></Box>
        <p>
          <br />
          Note: Hãy trả lời dưới dạng keyword hoặc 1 đoạn văn mô tả càng chi tiết càng tốt ý của bạn nhé, ví dụ:
          <br />
          <br />
          Hỏi: Bạn có thích trời mưa không?
          <br />
          Trả lời: Thôi không thích trời mưa vì đường tắc ngập, bận lắm!
        </p>
      </Box>
      <Box
        sx={{
          position: "relative",
          margin: "10px 0",
        }}
      >
        <Input
          multiline
          rows={2}
          sx={{
            flex: 1,
            border: "1px solid #e0e0e0",
            borderRadius: "10px",
            padding: "10px 30px 10px 10px",
            width: "100%",
            "&::after": {
              display: "none",
            },
            "&::before": {
              display: "none",
            },
          }}
          value={inputAns}
          onChange={(event) => setInputAns(event.target.value)}
          //   onKeyDown={(e) => {
          //     if (e.key === "Enter") {
          //       onSubmitAns();
          //     }
          //   }}
          placeholder="Enter my answer ..."
        />
        <IconButton
          className="absolute bottom-0 right-0"
          disabled={isLoading || inputAns.trim() === ""}
          onClick={() => onSubmitAns()}
        >
          {isLoading ? (
            <CircularProgress
              size={20}
              sx={{
                marginRight: "10px",
              }}
            />
          ) : (
            <SendIcon
              sx={
                inputAns.trim() === ""
                  ? {}
                  : {
                      color: "#0A14FF",
                    }
              }
            />
          )}
        </IconButton>
      </Box>
      {idea && (
        <>
          {isPartTwo ? (
            <Box>
              <Box
                sx={{
                  fontWeight: "bold",
                  fontSize: "15px",
                  marginBottom: "10px",
                }}
              >
                Gợi ý
              </Box>
              <Box
                sx={{
                  fontSize: "14px",
                }}
              >
                {idea}
              </Box>
              <Button
                variant="contained"
                sx={{
                  background: "#0A14FF",
                  color: "#fff",
                  textTransform: "unset",
                  float: "right",
                }}
                onClick={() => {
                  navigate(ROUTES.ANSWER_QUESTION.replace(":questionId", question?._id ?? ""), {
                    state: { isPartTwo: true, question, contentSuggestion: idea },
                  });
                }}
              >
                Customized
              </Button>
            </Box>
          ) : (
            <ListIdeaAI ideas={[idea]} />
          )}
        </>
      )}
    </Box>
  );
};

export default KeywordToIdea;
